<template>
  <v-col cols="12" class="pa-0">
    <v-row>
      <v-col v-for="(d, index) in list" :key="`${d.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
        <v-hover v-slot="{ hover }">
          <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click="goTo(d.id)">
            <v-row no-gutters>
              <v-icon color="primary">{{$route.name === 'BranchListCreate' ? 'mdi-store' : 'mdi-printer-pos'}}</v-icon>
              <v-spacer />
              <!-- <v-checkbox class="mr-n3" v-if="['PosListCreate'].includes($route.name)" v-model="selected" :value="d.id" dense multiple hide-details /> -->
            </v-row>
            <span class="d-block pt-2">
              <span class="fontBody--text font-weight-medium body-1 d-inline-block text-truncate" v-if="d.custom_id" style="max-width: 160px;">{{d.custom_id}}</span>
              <span class="d-block body-2 text--disabled font-italic mb-3" v-else>Sin especificar</span>
            </span>
            <span class="d-block body-2 mt-n2 fontDraw--text d-inline-block text-truncate" style="max-width: 160px; line-height: 22px;">
              <template v-if="$route.name === 'PosGroupsRetrieveUpdate'">{{ d.branch.name }}</template>
              <template v-else-if="$helpers.hasPermission(['view_pos'])">{{ currentDescription(d.branch || d.id) }}</template>
            </span>
            <span class="d-block mb-2 mt-3 body-2" v-if="['PosListCreate', 'PosGroupsRetrieveUpdate'].includes($route.name)">
              <v-chip :color="chipStatus[d.status].color" small label>
                <span class="font-weight-semibold">{{chipStatus[d.status].name}}</span>
                <v-icon right size="18">{{chipStatus[d.status].icon}}</v-icon>
              </v-chip>
            </span>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    chipStatus: {
      type: Object,
      default: () => {}
    },
    complements: {
      type: Array,
      default: () => []
    },
    selectedList: {
      type: Array,
      default: () => []
    },
    actionSelected: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    selected: []
  }),
  watch: {
    selected (val) {
      if (val.filter((item) => !!item.length).length) this.$emit('update:selectedList', val.filter((item) => item.length))
      else this.$emit('update:selectedList', [])
    },
    actionSelected (val) {
      // this.isReset += 1
      this.selected = this.list.map(({id}) => id)
    }
  },
  computed: {
    currentTo () {
      return this.$route.name === 'PosListCreate' ? 'PosRetrieveUpdate' : 'BranchRetrieveUpdate'
    },
    currentDescription () {
      return (val) => {
        if (['PosListCreate'].includes(this.$route.name)) {
          const result = this.complements.find(item => item.id === val)?.name ?? ''
          return `${result}`
        }

        const count = this.complements.filter(item => item.branch === val).length ?? ''
        return `${count} punto${count > 1 ? 's' : ''} de venta`
      }
    }
  },
  methods: {
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      if (this.$route.name !== 'PosGroupsRetrieveUpdate') this.$router.push({ name: this.currentTo, params: { id: id } })
    }
  }
}
</script>